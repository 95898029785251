import { api } from 'services';
import Transformer from './transformers';

class EnterpriseLocationService {
    /* static options(value) {
        return api.get(`/enterprise/locations?title=${value}`);
    } */

    static options({ title, ids }) {
        if (title) {
            return api.get(`/enterprise/locations?title=${title}`);
        }
        return api.get(`/enterprise/locations/${ids}`)
            .then(res => ({ data: [res] }));
    }

    static list(...args) {
        return api.list('/enterprise/locations', ...args)
            .then(Transformer.list);
    }

    static get(hash) {
        return api.get(`/enterprise/locations/${hash}`);
    }

    static sync(param) {
        return api.post(`/enterprise/locations/sync${param ? `/${param}` : ''}`);
    }
}

export { EnterpriseLocationService };
